import { UsaFlagIcon } from '../../../orders/create-order/steps/shipping/components/icon/UsaFlagIcon';
import { DeliveryTypes } from '../../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';
import React, { useContext } from 'react';
import { CreateUpgradeShippingContext, UpgradeShippingContextType } from '../../context/UpgradeShippingContextProvider';
import { ProgressSpinner } from 'primereact/progressspinner';
import currencyHelper from '../../../../../helpers/curreny.helper';


const ShippingInfoSection = () => {
	const context = useContext(CreateUpgradeShippingContext) as UpgradeShippingContextType;

	// const formatDate = (dateString: Date) => {
	// 	const date = new Date(dateString);
	// 	return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date);
	// };

	return (
		<section className={`container-body p-3 z-1`}>
			<h4 className="mb-5" style={{ fontSize: '1.5rem' }}>
				Current Shipping Information
			</h4>

			{context.loading && context.step.index === 0 ? (
				<div className="flex justify content-center align-items-center">
					<ProgressSpinner className="mr-auto ml-auto" style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
				</div>
			) : (
				<React.Fragment>
					<div className="flex gap-4">
						<div className="w-3">
							<h4 className="mb-4" style={{ fontSize: '1rem' }}>
								Shipping From
							</h4>

							<p>
								<UsaFlagIcon style={{ width: '1.5625rem', height: '0.8125rem' }} />
								<span className="ml-3">United States</span>
							</p>
						</div>

						<div className="w-3">
							<h4 className="mb-4" style={{ fontSize: '1rem' }}>
								Shipping to
								<span role="button" tabIndex={0} className="text-primary underline cursor-pointer ml-2 font-normal" onClick={() => context.goToStep(1)}>
									Edit
								</span>
							</h4>

							<p className="mb-0">
								{context.deliveryAddress?.receiverFullName}
								{context.order?.deliveryType === DeliveryTypes.PickUp ? (
									<>
										- <b>Pick Up</b>
									</>
								) : null}
							</p>

							{context.order?.deliveryType === DeliveryTypes.Shipment ? (
								<>
									<p className="mb-0">{context.deliveryAddress?.firstLine}</p>

									{!!context.deliveryAddress?.secondLine ? <p className="mb-0">{context.deliveryAddress.secondLine}</p> : null}

									<p className="mb-0">
										{context.deliveryAddress?.city}, {context.deliveryAddress?.state} {context.deliveryAddress?.zip} {context.deliveryAddress?.countryIso}
									</p>

									<p className="mb-0">
										{context.order?.orderAddress?.receiverPhoneNumber}
									</p>
								</>
							) : null}
						</div>

						{context.step.index !== 2 && (
							<div className="w-6">
								<React.Fragment>
									<h4 className="mb-4" style={{ fontSize: '1rem' }}>
										Current Shipping method
										<span role="button" tabIndex={0} className="text-primary underline cursor-pointer ml-2 font-normal" onClick={() => context.goToStep(2)}>
											Edit
										</span>
									</h4>

									{context.selectedRate ? <p>{`$${context.selectedRate.amount.toFixed(2)} - ${context.selectedRate.serviceName}`}</p> : <p>{`${currencyHelper.formatPrice(context.order?.transport?.priceForSeller?.formattedPricePerUnit!)} - ${context.order?.transport?.serviceName}`}</p>}
									{/*{context.selectedRate ? <p>{`$${context.selectedRate.amount.toFixed(2)} - ${context.selectedRate.serviceName} (Estimated delivery: ${context.selectedRate.estimatedDays} day(s))`}</p> : <p>{`$${context.order?.transport?.priceForSeller?.formattedPricePerUnit} - ${context.order?.transport?.serviceName} (Estimated delivery: ${context.order && formatDate(context.order?.transport?.estimatedDeliveryDateToCustomer!)})`}</p>}*/}
								</React.Fragment>
							</div>
						)}
					</div>
				</React.Fragment>
			)}
		</section>
	);
};

export default ShippingInfoSection;
