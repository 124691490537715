import {UpdateTransportDeliveryAddress} from "../update-transport/update-transport.request";

export class GetShippingRatesForUpgradeRequest {
	orderId: string;
	shipTo: UpdateTransportDeliveryAddress;

	constructor(orderId: string, shipTo: UpdateTransportDeliveryAddress) {
		this.orderId = orderId;
		this.shipTo = shipTo;
	}

	get getConvertUriExtension() {
		return `get-shipping-rates-for-upgrade`;
	}
}
