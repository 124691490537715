import React, { useContext, useEffect } from 'react';
import { CreateUpgradeShippingContext, UpgradeShippingContextType } from '../context/UpgradeShippingContextProvider';
import ShippingInfoSection from '../components/shipping-info-section/ShippingInfoSection';
import style from '../../orders/create-order/steps/shipping/components/rates/ShippingRates.module.scss';
import { RadioButton } from 'primereact/radiobutton';
import currencyHelper from '../../../../helpers/curreny.helper';
import PrButton from '../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import styles from '../../orders/create-order/steps/review-order/StepReviewOrder.module.scss';
import { BsFillShieldLockFill } from 'react-icons/bs';
import CostBreakdown from '../components/cost-breakdown/CostBreakdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { UpgradeShippingRateDto } from '../../../../features/orders/derived-features/order-transports/dtos/upgrade-shipping-rate.dto';
import { GetShippingRatesForUpgradeRequest } from '../../../../features/orders/derived-features/order-transports/get-shipping-rates-for-upgrade/get-shipping-rates-for-upgrade.request';
import { orderTransportService } from '../../../../features/orders/derived-features/order-transports/order-transport.service';

const ShippingMethod = () => {
	const context = useContext(CreateUpgradeShippingContext) as UpgradeShippingContextType;

	const [shippingRates, setShippingRates] = React.useState<UpgradeShippingRateDto[]>([]);

	useEffect(() => {
		getShippingRates();
	}, []);

	const getShippingRates = async () => {
		context.setLoading(true);
		if (!context.order) return;

		try {
			// const request = new GetShippingRatesForUpgradeRequest(context.order?.id);
			const request = new GetShippingRatesForUpgradeRequest(context.order?.id!, context.deliveryAddress!);
			const response = await orderTransportService.getShippingRatesForUpgrade(request);
			if (!response.isSuccess || !response.data) throw '';

			setShippingRates(response.data);
			context.setSelectedRate(response.data[0]);
		} catch (error) {
		} finally {
			context.setLoading(false);
		}
	};

	const onSelectedShipRateChange = (rate: UpgradeShippingRateDto) => {
		context.setSelectedRate(rate);
	};

	return (
		<React.Fragment>
			<ShippingInfoSection />

			<div className="container-body p-3 z-1">
				<div className={style.warningMessage}>If the cargo company calculates a different weight for the order, the difference will be charged.</div>

				<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
					Shipping method
				</h2>
				<div className={style.ratesWrapper}>
					{shippingRates.map((_rate, index) => (
						<div key={index} className="field-radiobutton">
							<RadioButton inputId={`rate${_rate.rateId || _rate.serviceName}`} value={_rate.rateId || _rate.serviceName} name="rate" onChange={() => onSelectedShipRateChange(_rate)} checked={context.selectedRate?.rateId ? _rate.rateId === context.selectedRate?.rateId : _rate.serviceName === context.selectedRate?.serviceName} />

							<label htmlFor={`rate${_rate.rateId || _rate.serviceName}`}>
								{currencyHelper.formatPrice(+_rate.amount)} - {_rate.serviceName}
							</label>
						</div>
					))}
					{context.loading && (
						<div className="flex justify content-center align-items-center">
							<ProgressSpinner className="mr-auto ml-auto" style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
						</div>
					)}
				</div>
			</div>

			{context.selectedRate && (
				<section className="container-body p-3 z-1">
					<CostBreakdown />

					<div className={styles.payButton}>
						<PrButton text="Continue to review" onClick={() => context.nextStep()} icon={<BsFillShieldLockFill />} btnType="button" />
						<div className={styles.payButtonDescription}>
							<div>By completing this order,</div>
							<div>I agree to Printram's Terms of Service.</div>
						</div>
					</div>
				</section>
			)}
		</React.Fragment>
	);
};

export default ShippingMethod;
